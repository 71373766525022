.wrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
  height: 100%;
}

@media only screen and (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
  }
}
