.labelInfo {
  font-size: var(--font-size-title);
  color: var(--text-transparent);
  font-weight: 600;
  margin: 8px 0;
  line-height: 1.5;
}

.modalInfo {
  margin-top: 15px;
}

.ratingInfo {
  color: var(--text-transparent);
  font-weight: 400;
}

.linkSection {
  display: flex;
  justify-content: space-between;
}

.link {
  color: var(--text-secondary);
  font-size: var(--font-size-label);
}
