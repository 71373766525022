.detailViewInformation {
  display: flex;
  margin-top: 15px;
  div {
    margin-right: 10px;
  }
}

.requestField {
  width: 50%;
}

@media only screen and (max-width: 1000px) {
  .requestField {
    width: 100%;
  }
}
