.userInfo {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 1000px) {
  .userInfo {
    flex-direction: column;
  }
}
