.buttonLogout {
  margin: 2px 0 0 10px;
  font-weight: 500;
}

.cardOption {
  display: flex;
  width: 100%;
  justify-content: start;
  background-color: var(--background);
  color: var(--text-transparent);
  font-size: var(--font-size-paragraph);
  cursor: pointer;
  margin-top: 10px;
}

.icon {
  color: var(--text-light-gray);
}
