.documentColumn {
  display: flex;
}

.productName {
  margin-left: 8px;
  margin-top: 10px;
}

.image {
  object-fit: cover;
}
