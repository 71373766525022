.availabilityChangeAdded,
.availabilityChangeReduce {
  width: 20px;
  margin-right: 30px;
  font-size: var(--font-size-title);
}

.availabilityChangeReduce {
  color: var(--error);
}

.availabilityChangeAdded {
  color: var(--success);
}

.saveProductStockButton {
  padding-right: 10px;
}

.acSpinner {
  opacity: 0.9;
}

.listWrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.noResultsText {
  text-align: 'center';
  margin-top: 40px;
}

.scrollableDiv {
  max-height: 400px;
  overflow-y: scroll;
}

.modalWrapper {
  background-color: var(--background);
  padding: 40px;
  border-radius: 10px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.stockInfoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.stockInfo,
.stockUpdateInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stockInfo {
  margin: 20px 0;
  font-size: var(--font-size-title);
  margin-bottom: 20px;
}

.stockUpdateInfo {
  width: 100px;
  div {
    margin: 0 5px;
  }
}

.modalFooter {
  display: flex;
  div {
    margin-right: 10px;
    width: 100%;
  }
}

.confirmationTitle {
  font-size: var(--font-size-subheader);
  margin: 25px 0;
}

.confirmModalButton {
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: 1;
  transition: left 0.4s;
  transform: translateX(20%);
}

.openFiltersButton {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
}

.title {
  font-weight: 650;
  color: var(--text-primary);
  font-size: var(--font-size-header);
  margin-top: 10px;
}

.buttonFilter {
  color: var(--text-secondary);
  background-color: var(--background-secondary);
}

.availabilityWrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .confirmModalButton {
    left: 20px;
    transform: translateX(0);
  }
}
