.statisticsList {
  padding: 10px 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.list,
.icon {
  display: flex;
}

.list {
  margin: 0px 0px 10px;
  align-items: center;
}

.descriptionBody {
  text-align: center;
  color: var(--text-transparent);
  font-weight: bold;
  margin: 25px 5px 10px;
  font-size: var(--font-size-paragraph);
  height: 210px;
  overflow: scroll;
}

.icon {
  width: 28px;
  height: 28px;
  color: var(--text-silver);
}
