.appLayoutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
  background-color: var(--text-secondary);
  color: var(--background);
  padding-left: 64px;
  padding-right: 64px;
  padding-bottom: 24px;
  padding-top: 24px;
}

.title {
  display: flex;
}

.image {
  text-align: center;
}

.icon {
  width: 25px;
  height: 25px;
  color: var(--background);
  border-radius: 50%;
}

@media only screen and (max-width: 1000px) {
  .appLayoutWrapper {
    display: none;
  }
}
