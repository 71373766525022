.form {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
  gap: 10px;
  max-width: 650px;
  justify-content: flex-start;
  padding-bottom: 30px;
}

.input {
  position: relative;
  margin: 10px 0;
}

.inputError {
  border-color: var(--error) !important;
}

.button,
.input,
.inputField {
  width: 100%;
}
.inputField {
  position: relative;
  padding: 10px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--text-secondary);
  height: 42px;
}

.form > div:last-of-type {
  min-width: 200px;
}

.errorMessage {
  color: var(--error);
  position: absolute;
  bottom: -30px;
  font-size: 10px;
  font-weight: 300;
  z-index: 5;
  height: 25px;
}

.button {
  position: relative;
  padding-top: 10px;
  min-width: 150px;
}

.buttonSpinner {
  transform: scale(0.6) translate(-70%, -80%) !important;
}

@media only screen and (max-width: 1200px) {
  .form {
    flex-direction: column;
    width: 100%;
    max-width: 500px;
  }
  .form > div:last-of-type {
    width: 100%;
  }
  .errorMessage {
    bottom: -25px;
  }
}
@media only screen and (max-width: 1000px) {
  .form {
    max-width: none;
    width: 100%;
  }

  .inputFiled {
    min-width: 97%;
  }
}
