.status {
  display: flex;
  align-items: center;
  span {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
  }
}

.seenInfo {
  margin-left: 4px;
}

.red {
  color: var(--error);
  span {
    background-color: var(--error);
  }
}

.green {
  color: var(--success);
  span {
    background-color: var(--success);
  }
}

.orange {
  color: var(--warning);
  span {
    background-color: var(--warning);
  }
}
