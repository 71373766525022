.userInfoWrapper {
  display: flex;
  margin: 15px 0;
}

.iconProfile {
  width: 30px;
  height: 30px;
  color: var(--text-transparent);
}

.userProfile {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  padding-bottom: 15px;
}

.userPhone {
  display: flex;
  align-items: center;
  margin-left: 20%;
  padding-bottom: 15px;
}

.userComment {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.userInfoWrapper,
.userComment {
  border-bottom: 2px solid #e5e7eb;
}

.userPhone,
.userName,
.userComment {
  font-weight: 600;
  font-size: var(--font-size-label);
}

.userCardWrapper {
  display: flex;
  justify-content: space-between;
}

.link {
  color: var(--text-secondary);
  font-size: var(--font-size-label);
}

.userName {
  margin-left: 10px;
}

.userCard {
  padding: 20px;
}

@media only screen and (max-width: 760px) {
  .userInfoWrapper {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }

  .userPhone,
  .userProfile {
    border-bottom: 2px solid #e5e7eb;
    margin: 15px 0;
    padding-bottom: 15px;
  }
}
