.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  padding: 0 30px;
}

.textCheckbox {
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--text-transparent);
}

.textField {
  color: var(--text-primary);
}

.buttonsWrapper,
.textField,
.regLink {
  margin-top: 24px;
}

.passwordLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 10px;
}

.regLink {
  text-align: center;
}

.link {
  color: var(--text-secondary);
}

.languageWrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 200px;
}

@media only screen and (max-width: 760px) {
  .languageWrapper {
    position: relative;
    top: 10px;
    left: 0%;
    bottom: 0%;
    width: 100%;
  }
}
