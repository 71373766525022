.basicInformationSection {
  flex: 1;
  flex-direction: column;
  margin-right: 20px;
}

.showInApp {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}

.showLabel {
  color: var(--text-transparent);
}
