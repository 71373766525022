.icon {
  color: var(--text-primary);
}

.verifyIcon {
  color: var(--success);
}

.inPrepIcon {
  color: var(--error);
}

.place {
  display: flex;
  align-items: center;

  div {
    margin-left: 15px;
  }
}
