.form {
  margin-right: 30px;
}

.title {
  font-weight: 700;
  margin: 20px 0;
}

.cards,
.cardsTable {
  display: flex;
  gap: 16px;
  margin: 0;
  > div {
    margin: 0;
    width: 100%;
  }
}

.cards {
  margin-top: 30px;
  margin-bottom: 10px;
}
.cardsTable {
  > div {
    margin: 0;
    margin-top: 16px;
  }
}

.table {
  width: 100%;
  padding: 15px;
  margin: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.subtitle {
  margin-top: 12px;
  padding-left: 8px;
  border-left: 3px solid var(--text-silver);
  color: var(--text-silver);
}

@media only screen and (max-width: 1000px) {
  .cards,
  .cardsTable {
    flex-direction: column;
  }
  .form {
    margin-right: 0;
  }
}
