.headerTitle {
  font-size: var(--font-size-title);
  font-weight: bold;
  margin-bottom: 15px;
}

.activityCard {
  padding: 20px;
  margin: 15px 0;
}

.title {
  margin-bottom: 5px;
}
.timeLine {
  display: flex;
  align-items: flex-end;
}

.label {
  color: var(--text-transparent);
}
