.form {
  flex: 2;
  flex-direction: column;
}

.divider {
  width: 100%;
  margin-bottom: 16px;
  border-top: 1px solid rgb(228, 231, 235);
}

.card {
  padding: 10px;
  border-radius: 10px;
}

.icon {
  width: 30px;
  height: 30px;
}

.dataWrapper {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  margin: 20px 10px;
}

.card,
.dataWrapper,
.rolesName {
  border-bottom: 2px solid #e5e7eb;
}

.textData {
  margin-left: 30px;
}

.rolesData {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.rolesName {
  margin-bottom: 10px;
  font-weight: 600;
}

.placeName {
  margin-bottom: 10px;
}

.lastActiveAt {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 760px) {
  .form {
    display: flex;
    flex-direction: row;
  }

  .baseInfoSection {
    height: max-content;
    width: 45%;
  }

  .rolesInfoSection {
    height: max-content;
    margin-left: 45px;
    width: 45%;
  }
}
