:root {
  --background: rgb(255 255 255);
  --text-primary: rgb(17 24 39);
  --text-secondary: #014342;
  --text-silver: rgb(75 85 99);
  --text-transparent: rgb(107 114 128);
  --text-light-gray: rgb(125 125 125);
  --background-secondary: rgb(243 244 246);
  --font-size-header: 24px;
  --font-size-subheader: 20px;
  --font-size-title: 18px;
  --font-size-paragraph: 16px;
  --font-size-label: 14px;
  --font-size-small: 12px;
  --error: #e8362c;
  --success: #2fb551;
  --info: #fbd257;
  --warning: #eeaa00;
}

.app {
  color: var(--text-primary);
  background-color: var(--background);
  transition: all 0.5s;
}

.mainWrapper {
  display: flex;
  width: 100%;
}

.mainContent {
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  padding-left: 310px;
  overflow: scroll;
}

.authLayout {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.appContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67%; /* Adjusted for larger screens */
  height: 100vh;
}

@media only screen and (max-width: 1000px) {
  .appContent {
    width: 100%; /* Adjusted for smaller screens */
  }
  .mainContent {
    padding-left: 30px; /* Adjusted for smaller screens */
  }
}

@media only screen and (max-width: 760px) {
  .appContent {
    height: fit-content;
  }
}
