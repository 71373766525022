.form {
  margin-right: 30px;
}

.title {
  font-weight: 700;
  margin: 20px 0;
}

.cards {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0;
  gap: 16px;
  > div {
    margin: 0;
  }
}

.userInformation {
  display: flex;
  gap: 30px;
  width: 80%;
}

.pieChartSegment {
  width: 50%;
}

.cardsTable {
  display: flex;
  gap: 16px;
  max-width: 80%;

  > div {
    margin: 0;
    margin-top: 16px;
  }
}

.orderStatisticsCard {
  width: 40%;
}

.barChartSegment {
  max-width: 80%;
}

.barChart {
  padding: 20px 10px;
}

.table {
  width: 100%;
}

.pieChart,
.table {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 15px;
}

.subtitle {
  margin-top: 12px;
  padding-left: 8px;
  border-left: 3px solid var(--text-silver);
  color: var(--text-silver);
}

@media only screen and (max-width: 1200px) {
  .cards {
    display: flex;
    width: 100%;
  }

  .userInformation,
  .cardsTable,
  .cards {
    flex-direction: column;
  }

  .userInformation {
    gap: 0;
    width: 100%;
  }
  .cardsTable {
    max-width: 100%;
  }
  .cardsTable {
    > div {
      max-width: 100%;
    }
  }
  .orderStatisticsCard {
    width: 100%;
  }
  .pieChartSegment {
    width: 100%;
    margin: 16px 0;
  }
  .form {
    margin-right: 0;
  }
  .barChartSegment {
    max-width: 100%;
  }
}

@media only screen and (max-width: 760px) {
  .cardsTable {
    > div {
      max-width: 100%;
    }
  }
  .cards {
    width: 100%;
  }
  .pieChartSegment {
    width: 100%;
  }
  .orderStatisticsCard {
    width: 100%;
  }
  .barChartSegment {
    max-width: 100%;
  }
  .userInformation {
    width: 100%;
  }
  .cardsTable {
    max-width: 100%;
  }
}
