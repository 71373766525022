.modalAdd {
  background-color: var(--background);
  padding: 35px;
  border-radius: 5px;
  width: 400px;
  overflow: inherit;
}

.addTitle {
  margin: 0 20px 10px;
  text-align: center;
}

.buttonsWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  div:first-child {
    margin-right: 10px;
  }
  div {
    width: 100%;
  }
}

.subtitle {
  margin: 0 20px 20px;
  text-align: center;
}
