.buttonWrapper {
  margin-top: 24px;
}

.input {
  padding: 20px 0px 20px 0px;
}

.options {
  display: flex;
}

.name {
  margin-left: 8px;
}

