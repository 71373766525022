.orderPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  border-bottom: 2px solid #e5e7eb;
  font-size: var(--font-size-label);
  color: var(--text-transparent);
  span {
    font-weight: 600;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.paymentCard {
  padding: 20px;
  height: auto;
}
