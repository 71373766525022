.description,
.icon {
  display: flex;
}

.nameBody,
.icon {
  margin: 15px 0 10px;
}

.nameBody,
.descriptionBody {
  font-weight: bold;
}

.statisticsCard {
  padding: 10px 20px;
  margin: 15px 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  height: 140px;
}

.nameBody {
  font-size: var(--font-size-label);
  color: var(--text-primary);
}

.descriptionBody {
  text-align: center;
  color: var(--text-transparent);
  margin: 15px 5px 10px;
  font-size: var(--font-size-header);
}

.icon {
  width: 28px;
  height: 28px;
  color: var(--text-silver);
}
