.sideBar {
  padding-left: 16px;
  padding-right: 16px;
}

.icon {
  display: flex;
  width: 28px;
  height: 28px;
  color: var(--text-silver);
  position: relative;
}

.header {
  display: flex;
  justify-content: center;
  color: var(--text-silver);
  margin: 20px 0 25px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}

.navItem {
  padding-right: 0;
}
.logo {
  margin-right: 8px;
}
