.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65%;
}

.title {
  font-size: var(--font-size-header);
  font-weight: 600;
  line-height: 32px;
}

.text {
  font-size: var(--font-size-label);
  margin-bottom: 8px;
  color: var(--text-transparent);
  font-weight: 600;
}

.Select {
  width: 400px;
  height: 40px;
}

.buttonsWrapper {
  margin-top: 24px;
}

.passwordLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.regLink {
  text-align: center;
  margin-top: 24px;
}

.link {
  color: var(--text-secondary);
}

@media only screen and (max-width: 1200px) {
  .form {
    width: 75%;
  }
}

@media only screen and (max-width: 760px) {
  .form {
    margin-top: 30px;
  }
}
