.image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  margin-top: 10px;
}

.placeName {
  align-items: center;
  font-size: var(--font-size-label);
  color: var(--text-transparent);
  font-size: var(--font-size-label);
}

.placeCard {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  cursor: pointer;
  width: 100%;
  transform: scale(1);
  transition: transform 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) !important;
  &:hover {
    transform: scale(1.025);
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 30px;
}

.singleCardContainer {
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.placeNameBody {
  font-size: var(--font-size-label);
  color: var(--text-transparent);
  font-size: var(--font-size-subheader);
  font-weight: bold;
  margin: 15px 0 10px;
}

.placeDetails {
  display: flex;
  align-items: center;

  div {
    margin-left: 5px;
  }
}

.placesHeader {
  margin: 10px 0 25px 0;
  color: var(--text-transparent);
  font-size: var(--font-size-subheader);
}

.pagination {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

.pagination:hover {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

@media only screen and (max-width: 600px) {
  .cards,
  .singleCardContainer {
    grid-template-columns: 1fr;
  }
}
