.phoneNumberForm {
  padding-bottom: 24px;
  padding-top: 45px;
  padding-right: 16px;
}

.titlePhoneNumber {
  font-size: var(--font-size-title);
  color: var(--text-primary);
  font-weight: 600;
  line-height: 28px;
}

.description,
.textFieldCurrentPass,
.textField {
  color: var(--text-transparent);
}

.textFieldCurrentPass,
.textField {
  height: 40px;
}

.description {
  font-size: var(--font-size-label);
}

.textFieldCurrentPass {
  margin-top: 48px;
}

.textField {
  margin-top: 32px;
}

.text {
  font-size: var(--font-size-label);
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;
  color: var(--text-transparent);
}

.inputTextField {
  color: var(--text-primary);
  width: 85%; /* Updated for desktop */
}

.titlePreferences {
  line-height: 28px;
  margin-top: 80px;
  background-color: var(--error);
}

.divider {
  color: var(--text-transparent);
  margin-top: 32px; /* Updated for desktop */
}

.phoneNumber {
  display: flex;
  margin-top: 35px;
  gap: 16px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 10px;
}

.buttonWrapper {
  width: 100%;
}

.options {
  display: flex;
}

.name {
  margin-left: 8px;
}

.description {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .textField {
    margin-top: 32px;
    color: var(--text-transparent);
    height: 44px;
    display: flex;
  }

  .text {
    font-size: var(--font-size-label);
    font-weight: 600;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    color: var(--text-transparent);
  }

  .divider {
    color: var(--text-transparent);
    margin-top: 64px;
  }

  .inputTextField {
    color: var(--text-primary);
    width: 100%;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div:first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .buttonWrapper {
    width: 100%;
  }
  .phoneNumber {
    flex-direction: column;
  }
  .phoneNumberForm {
    padding-right: 0;
  }

  .description {
    margin-bottom: 0px;
  }
  .phoneNumber {
    margin-top: 30px;
  }
}
