//TODO: this is temporary solution before acinput styling configuration will be fixed
.inputsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.inputHalfWidth {
  width: 49%;
}

@media only screen and (max-width: 1000px) {
  .formWrapper {
    width: 98%;
  }

  .inputsContainer {
    flex-direction: column;
    gap: 16px;
  }

  .inputHalfWidth {
    width: 100%;
  }
}
