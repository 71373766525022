.basicInformationSection {
  flex: 2;
  flex-direction: column;
}

.pricing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.divider {
  width: 100%;
  margin-bottom: 16px;
  border-top: 1px solid rgb(228, 231, 235);
}

.serialNumber {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}
