.textField {
  margin: 24px 8px 0 0;
  color: var(--text-primary);
  width: 100%;
}

.name {
  margin-left: 8px;
}

.text {
  font-size: var(--font-size-label);
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--text-transparent);
  font-weight: 600;
}

.inputFields,
.options {
  display: flex;
}

.options {
  display: flex;
  padding: 5px;
}

@media only screen and (max-width: 760px) {
  .inputFields {
    flex-wrap: wrap;
  }
}
