.form {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
  gap: 10px;
  max-width: 650px;
  padding-bottom: 30px;
}

.input {
  margin: 10px 0;
  position: relative;
}

.button,
.input,
.inputField {
  width: 100%;
}

.inputField {
  padding: 10px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid var(--text-secondary);
  height: 42px;
}
.inputError {
  border-color: var(--error);
}

.errorMessage {
  color: var(--error);
  position: absolute;
  bottom: -30px;
  font-size: 10px;
  font-weight: 300;
  z-index: 5;
  height: 25px;
}

.inputError {
  border-color: var(--error) !important;
}

.button {
  position: relative;
  min-width: 150px;
}

.buttonSpinner {
  transform: scale(0.6) translate(-70%, -80%) !important;
}

@media only screen and (max-width: 1000px) {
  .form {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    padding-bottom: 0;
  }
  .errorMessage {
    bottom: -25px;
  }
}
@media only screen and (max-width: 600px) {
  .form {
    max-width: none;
    width: 100%;
  }

  .inputFiled {
    min-width: 97%;
  }
}
