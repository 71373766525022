.product {
  display: flex;
  align-items: center;

  div {
    margin-left: 15px;
  }
}

.productImage {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.red {
  color: var(--error);
}

.orange {
  color: var(--warning);
}

.green {
  color: var(--success);
}

.status {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  span {
    font-weight: 600;
  }
}

.seen {
  margin-top: 15px;
  span {
    font-weight: 600;
  }
}
