.contentWrapper {
  background: var(--background);
  width: 530px;
  height: 216px;
  border-radius: 8px;
}

.contentWrapperReject {
  background: var(--background);
  width: 570px;
  height: 385px;
  border-radius: 8px;
}

.infoWrapperReject {
  padding: 32px 24px 24px 24px;
  height: 130px;
  background: var(--background);
  display: flex;
}

.infoWrapper {
  padding: 32px 24px 24px 24px;
  height: 155px;
  background: var(--background);
  display: flex;
}

.mainWrapper {
  margin-left: 16px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-right: 30px;
}

.inputWrapper {
  overflow: auto;
}

.title {
  font-size: var(--font-size-subheader);
  font-weight: 600;
}

.text {
  font-size: var(--font-size-label);
  margin-bottom: 8px;
  color: var(--text-light-gray);
}

.iconClose {
  height: 30px;
  width: 30px;
  right: 24px;
  padding: 4px;
  position: absolute;
  cursor: pointer;
  color: var(--text-transparent);
  margin-top: 24px;
}

.buttonCancel,
.buttonConfirm {
  margin-right: 12px;
}

.spinnerCancel {
  color: white;
}

.spinnerSubmit {
  color: var(--text-secondary);
}
