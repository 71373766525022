html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ACMenuLine {
  background-color: var(--background) !important;
}

.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.selectEffect {
  background-color: #e2e4e7;
  opacity: 1 !important;
}

.ACDrawer {
  width: 270px !important;
  min-width: 270px !important;
}

.ACDrawer--shrinked {
  width: 120px !important;
  min-width: 120px !important;
}

.ACDrawer__nav-item-wrap {
  z-index: 1;
}

.ACDrawer__nav-item {
  padding-bottom: 0 !important;
  border-radius: 8px;
  margin-bottom: 8px;
  &:hover {
    background-color: #dcdde1;
  }
}

.ACTabs__header-button--active {
  border-bottom-width: 2px;
  font-size: var(--font-size-label);
  color: var(--text-secondary) !important;
  border-bottom: 2px solid var(--text-secondary) !important;
}

.ACTabs__header-button:hover {
  color: var(--text-secondary) !important;
}
