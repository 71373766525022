.uploadProductForm {
  .ACUploadInput-container {
    height: 100px !important ;
  }
  .ACUpload-wrapper {
    display: flex !important;
    flex-wrap: wrap;
  }
  .ACFile-Item-Image-Modal {
    width: 500px !important;
    height: 500px !important;
    padding: 24px !important;
    img {
      width: 100% !important;
      height: 420px !important;
      max-height: 100% !important;
      object-fit: cover !important;
    }
  }
}