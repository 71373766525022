.form {
  margin-right: 30px;
}

.pieChartAndCards {
  display: flex;
  width: 80%;
  gap: 20px;
  flex-wrap: wrap;
}

.cards,
.cardsTable {
  display: flex;
}

.cards {
  width: fit-content;
  flex-direction: column;
  width: 35%;
}

.cardNumberInfo {
  display: flex;
  width: 50%;
  gap: 10px;
  margin-top: 15px;
}

.table,
.cardsTable {
  width: 80%;
}

.barChartSegment {
  width: 80%;
}

.pieChartSegment {
  width: 60%;
}

.cardsTable {
  display: flex;
  gap: 10px;
}

.table,
.pieChart,
.barChart {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.barChart,
.pieChart,
.table {
  padding: 15px;
  margin: 15px 0;
}

.subtitle {
  margin-top: 12px;
  border-left: 3px solid var(--text-silver);
  padding-left: 8px;
  color: var(--text-silver);
}

@media only screen and (max-width: 1200px) {
  .pieChartAndCards {
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}

.cardNumberInfo {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
}

  .table,
  .cardsTable {
    width: 100%;
  }

  .barChartSegment {
    width: 100%;
  }

  .pieChartSegment {
    width: 60%;
  }

  .cards {
    width: 37%;
    margin-right: 0px;
  }

  .form {
    margin-right: 0;
  }
}

@media only screen and (max-width: 992px) {
  .cardNumberInfo {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 0px;
}

  .table,
  .cardsTable {
    width: 100%;
  }

  .pieChartSegment,
  .barChartSegment {
    width: 100%;
  }

  .barChart,
  .pieChart {
    padding: 15px;
  }

  .pieChartAndCards {
    gap: 15px;
  }

  .pieChartAndCards,
  .cards,
  .cardsTable {
    flex-direction: column;
  }

  .cards,
  .cardsTable {
    width: 100%;
  }
}

@media only screen and (max-width: 760px) {
  .table,
  .cardsTable {
    width: 100%;
  }

  .barChartSegment {
    width: 100%;
  }

  .pieChartSegment {
    width: 100%;
  }
}
