.Navbar {
  position: fixed;
  z-index: 10;
}

.AppBar {
  display: flex;
  justify-content: space-between;
  background-color: var(--background);
  width: 100vw;
  height: 65px;
}

.MenuBtn {
  margin-left: 16px;
}

.currentUser {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconProfile {
  color: var(--text-transparent);
}

.currentUserInfo {
  color: var(--text-transparent);
  font-weight: 600;
  margin-left: 10px;
}

.navBarCard {
  position: relative;
}

.roleUser {
  font-size: var(--font-size-label);
}

.drawerButton {
  color: var(--text-transparent);
  width: 35px;
  height: 35px;
}

@media only screen and (min-width: 600px) {
  .AppBar {
    background-color: var(--background);
    width: 100vw;
    height: 70px;
  }

  .MenuBtn {
    display: none;
  }

  .drawerButton {
    visibility: hidden;
  }
}
