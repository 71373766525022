.box {
  padding: 16px;
}

.settingsTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
}

@media screen and (max-width: 1000px) {
  .box {
    padding: 16px 0;
  }
}
