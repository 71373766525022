.workingHours {
  display: flex;
  gap: 20px;
  margin-bottom: 0;
  .workDay {
    width: 10%;
    padding-top: 10px;
  }
}

.workingHoursHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.errorMessages {
  width: 40%;
  color: var(--error);
  font-size: 12px;
  margin: 10px;
  text-align: center;
}

.arrowButton {
  margin-left: 30px;
}

@media only screen and (max-width: 1440px) {
  .errorMessages {
    width: 50%;
  }
}

@media only screen and (max-width: 760px) {
  .workingHours {
    flex-direction: column;
    margin-bottom: 10px;
    align-items: flex-start;
    gap: 10px;

    &:last-of-type {
      padding-bottom: 50px;
    }
    .workDay {
      width: 100%;
    }
  }
  .errorMessages {
    text-align: left;
  }
}
