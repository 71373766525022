.modalWrapper {
  background: var(--background);
  width: 650px;
  margin: auto;
}

.mainWrapper {
  margin-left: 16px;
  padding: 32px 24px;
}

.title {
  font-size: var(--font-size-header);
  font-weight: 600;
  margin-bottom: 5px;
}

.text {
  font-size: var(--font-size-title);
  margin-bottom: 8px;
  color: var(--text-light-gray);
}

.buttonCancel {
  margin-right: 12px;
}

.headerModal {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .modalWrapper {
    width: 40%;
  }
}
