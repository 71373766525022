.filterButton {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

.headerActions {
  background-color: var(--background-secondary);
}

.headerActions > svg {
  fill: var(--text-secondary);
  color: var(--background);
}

.paginationButtonActive {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

.paginationButtonActive:hover {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

.actions {
  color: var(--text-secondary);
}
