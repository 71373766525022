.placeInfo {
  display: flex;
  padding: 5px 0;

  div {
    margin-right: 5px;
  }
}

.ordersPlaceCard {
  padding: 20px;
  margin: 15px 0;
}

.link {
  color: var(--text-secondary);
  font-size: var(--font-size-label);
}

.userCardWrapper {
  display: flex;
  justify-content: space-between;
}
