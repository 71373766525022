.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  div {
    width: 100%;
  }
  div:first-child {
    margin-right: 8px;
  }
}

.buttonWrapper {
  width: 48%; /* Adjusted width for two columns */
  margin-top: 24px;
}

.input {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 760px) {
  .form {
    width: 100%;
  }

  .buttonsWrapper {
    flex-direction: column;
    justify-content: space-between;

    .buttonWrapper {
      width: 100%;
      margin-top: 16px;
    }
  }

  .input {
    justify-content: flex-start;
  }
}
