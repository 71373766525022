.basicInformationSection {
  flex: 1 1;
  flex-direction: column;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.divider {
  width: 100%;
  margin-bottom: 16px;
  border-top: 1px solid rgb(228, 231, 235);
}

.infoCodeWeight {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

@media screen and (max-width: 600px) {
  .infoCodeWeight,
  .price {
    flex-direction: column;
    margin-top: 16px;
    gap: 0;
  }
}

.pickupHoursWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 450px;
  margin: 24px 0;
}

.pickupHours {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

.showWeightWrapper {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  max-width: 450px;
  margin: 24px 0;
}

.showWeightInfo {
  display: flex;
  gap: 12px;
}

.showWeightText {
  margin-top: 3px;
}

.initialDailyAvailableWrapper {
  display: flex;
  gap: 12px;
}

.infoCircleIcon {
  margin-top: 26px;
}
