.buttonsWrapper {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: 20px 0;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.buttonWrapper {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .buttonsWrapper {
    margin-top: 60px;
    display: block;
    padding: 0;
  }
}
