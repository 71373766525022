.inputsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  textarea {
    height: auto;
  }
}

.minimalVersion {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.showInApp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.showLabel {
  color: var(--text-transparent);
}

.loginTypesSubtitle {
  margin-top: 5px;
  color: var(--text-transparent);
}

.showInformation {
  display: flex;
  flex-direction: column;
}
