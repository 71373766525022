.wrapper {
  display: flex;
  gap: 16px;
}

@media only screen and (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
  }
}
