.statisticsCard {
  padding: 10px 20px;
  margin: 15px 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 100%;
}

.description {
  display: flex;
}
