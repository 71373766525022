.orderStatusTitle {
  margin: 10px 0 10px 0;
}

.buttonsAcceptReject {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.reasonMessage {
  margin: 0 30px 20px;
}

.reasonTitle {
  margin-bottom: 10px;
  font-size: var(--font-size-label);
  color: var(--text-transparent);
}
