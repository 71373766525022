.red,
.green,
.orange,
.addInfoColor {
  display: flex;
  align-items: center;
}

.red {
  color: var(--error);
}

.green {
  color: var(--success);
}

.orange {
  color: var(--info);
}

.addInfoColor {
  color: var(--warning);
}
