@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
}

.logo {
  width: 150px;
  height: 150px;
  animation: pulse 2s ease-in-out infinite;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background); /* Set the background to transparent */
  z-index: 100; /* Adjust the z-index to make sure it's on top of other content */
}
