.passwordForm {
  padding-bottom: 14px;
  padding-top: 24px;
  padding-right: 16px;
}

.titlePassword {
  font-size: var(--font-size-title);
  color: var(--text-primary);
  font-weight: 600;
  line-height: 28px;
}

.description {
  color: var(--text-transparent);
  font-size: var(--font-size-label);
}

.textFieldCurrentPass {
  margin-top: 48px;
  color: var(--text-transparent);
  height: 40px;
  display: flex;
}

.textField {
  margin-top: 32px;
  color: var(--text-transparent);
  height: 40px;
  display: flex;
}

.text {
  font-size: var(--font-size-label);
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  color: var(--text-transparent);
  width: 40%;
}

.inputTextField {
  color: var(--text-primary);
  width: 100%;
}

.titlePreferences {
  line-height: 28px;
  margin-top: 80px;
}

.Select {
  width: 100%;
  height: 48px;
  background-color: var(--background);
  color: var(--text-transparent);
}

.divider {
  color: var(--text-transparent);
  margin-top: 64px;
}

.inputTextField {
  color: var(--text-primary);
  width: 85%;
}

@media only screen and (max-width: 1000px) {
  .passwordForm {
    padding-right: 0;
  }
  .divider {
    color: var(--text-transparent);
    margin-top: 32px;
  }
  .inputTextField {
    width: 100%;
  }
  .textFieldCurrentPass,
  .textField {
    flex-direction: column;
  }
  .textFieldCurrentPass {
    margin-top: 10px;
  }
}
