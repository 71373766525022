.textField {
  color: var(--text-primary);
}

.text {
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--text-transparent);
  font-size: var(--font-size-label);
}

.buttonsWrapper,
.regLink,
.textField {
  margin-top: 24px;
}

.regLink {
  text-align: center;
}

.link {
  color: var(--text-secondary);
}
