.documentColumn {
  display: flex;
}

.productCategoryName {
  margin-left: 8px;
  margin-top: 10px;
}

.categoryImage {
  object-fit: cover;
}
