.languageForm {
  padding: 24px 16px;
}

.description,
.textFieldPerformances,
.text {
  color: var(--text-transparent);
}

.titlePreferences {
  color: var(--text-primary);
}

.description {
  font-size: var(--font-size-label);
}

.textFieldPerformances {
  height: 44px;
  display: flex;
  margin-top: 40px;
}

.text {
  font-size: var(--font-size-label);
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  width: 40%;
}

.inputTextField {
  width: 85%;
  color: var(--text-primary);
}

.titlePreferences {
  font-size: var(--font-size-title);
  font-weight: 600;
  line-height: 28px;
  margin-top: 80px;
}

@media only screen and (max-width: 1000px) {
  .textFieldPerformances {
    height: 40px;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .text,
  .inputTextField {
    width: 100%;
  }
  .languageForm {
    padding: 16px 0;
  }
  .titlePreferences {
    margin-top: 50px;
  }
}
