.icon {
  color: var(--text-primary);
}

.inPrepIcon {
  color: var(--error);
}

.activeIcon {
  color: var(--success);
}

.place {
  display: flex;
  align-items: center;

  div {
    margin-left: 15px;
  }
}

.placeImage {
  width: 70px;
  height: 70px;
}
