.menuBar {
  position: absolute;
  top: 50px;
  right: -5px;
  padding: 15px;
  width: max-content;
  z-index: 1;
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 5px;
  background-color: var(--background);
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 9px;
}

.menuBar.close {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 0.3s ease-in-out;
}

.iconProfile {
  color: var(--text-transparent);
  margin-right: 5px;
}

.userInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(229, 231, 235, 0.8);
}

.emailInfo {
  font-size: var(--font-size-small);
}

.userData {
  margin-bottom: 2px;
  color: var(--text-transparent);
  font-weight: 700;
}

.profileTab {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid rgba(229, 231, 235, 0.8);
  padding: 10px 0;
  margin-bottom: 10px;
  color: var(--text-transparent);
}

.guideTab {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid rgba(229, 231, 235, 0.8);
  padding-bottom: 10px;
  color: var(--text-transparent);
}

.icon {
  color: var(--text-transparent);
  margin-right: 10px;
}

.avatar {
  margin-right: 10px;
}

.buttonLogout {
  font-weight: 500;
}
