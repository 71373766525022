.statisticsCard {
  padding: 10px 20px;
  margin: 15px 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  height: 220px;
}

.infoCard {
  margin: 0px 0px 10px;
  align-items: center;
}

.description,
.infoCard {
  display: flex;
}

.descriptionBody {
  color: var(--text-transparent);
  font-weight: bold;
  margin: 25px 5px 10px;
  font-size: var(--font-size-paragraph);
}
