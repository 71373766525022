.footer {
  display: flex;
  background-color: var(--background);
  padding: 16px;
  border-top: 1px solid rgb(228, 231, 235);
  position: absolute;
  width: 100%;
  bottom: 0;
  color: var(--background);
  font-size: 25px;
}

.footerContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.actionButtons,
.leftActionButtons {
  display: flex;
  align-items: center;
}

.actionButtons {
  justify-content: flex-end;
  gap: 10px;
}

.leftActionButtons {
  gap: 10px;
}
