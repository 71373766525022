.divider {
  width: 100%;
  margin: 15px 0 25px;
  border-top: 1px solid rgb(228, 231, 235);
}

.addressInformation {
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  width: 100%;
}

.activityInformation {
  display: flex;
  align-items: flex-start;
  margin: 30px 20px 0 0;
  width: 100%;
}

.formInformation {
  display: flex;
}

.basicInfo {
  flex: 1;
  margin: 0 30px 20px 0;
}

.mapInformation {
  flex: 1;
}

.placeAdressTitle {
  font-size: 'var(--font-size-paragraph)';
  color: 'var(--text-transparent)';
  margin-bottom: 20px;
}

.mapHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}
.mapHeaderTitle {
  font-size: var(--font-size-paragraph);
  color: var(--text-transparent);
}

.editPlaceForm {
  padding: 0;
}

.beneficiary {
  margin-top: 15px;
  gap: 15px;
  display: flex;
}

.showWeightWrapper {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  max-width: 450px;
  margin-top: 24px;
}

.showWeightInfo {
  display: flex;
  gap: 12px;
}

.showWeightText {
  margin-top: 3px;
}

@media only screen and (max-width: 760px) {
  .addressInformation,
  .activityInformation {
    flex-direction: column;
    margin-right: 0;
  }

  .formInformation {
    flex-direction: column;
  }
  .basicInfo {
    margin: 0 0 20px 0;
  }
}

.editInfo {
  display: flex;
  align-items: center;
}
