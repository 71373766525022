.uploadDocumentSection {
  flex: 1;
  flex-direction: column;
}

.uploadText {
  margin-bottom: 4px;
  color: var(--text-transparent);
  &:last-of-type {
    margin-bottom: 15px;
  }
}
