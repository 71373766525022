.buttonsWrapper {
  display: flex;
  margin-top: 20px;
  div {
    width: 100%;
  }

  div:first-child {
    margin-right: 10px;
  }
}

.description {
  margin-top: 10px;
}

.title {
  font-size: var(--font-size-subheader);
}

.form {
  display: flex;
  align-items: center;
}

.icon {
  width: 70px;
  height: 70px;
  color: var(--text-secondary);
  text-align: center;
  margin-right: 10px;
}
