.userCards {
  display: flex;
  flex-wrap: wrap;
}

.userCard {
  margin-right: 30px;
  width: 45%;
  height: max-content;
}

.userInformation {
  display: flex;
  align-items: center;
  padding: 20px;
}

.userActivity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px;
}

.basicInfo {
  padding: 0 15px 0;
}

@media only screen and (max-width: 1000px) {
  .userCard {
    width: 100%;
  }
}
