.wrapper {
  display: flex;
  flex-direction: column;
}

.actions {
    display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.actionButtons {
  cursor: pointer;
  margin-top: 8px;
}

.actionButtons:hover {
  background-color: var(--background-secondary);
}