.form {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.image,
.icon,
.title,
.description {
  text-align: center;
}

.buttonWrapper {
  margin-top: 24px;
  width: 100%;
}
