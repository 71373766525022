.reviewCard {
  padding: 20px;
}

.rating {
  padding-top: 10px;
}

.orderRating {
  svg {
    padding-top: 4px;
  }
}
